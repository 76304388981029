// css reset from tailwind
// this is required because we need to preserve some of global styling
// after removal
// see: https://tailwindcss.com/docs/preflight#overview
@import './preflight.tailwind.css';

$theme-colors: (
  'orange': #feb81c,
  'gray': #e8e8e8,
  'darkgray': #c8c7c7,
);

@each $color, $value in $theme-colors {
  .customIllumeScrollbar-#{$color} {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: ($value);
      border-radius: 5px;
    }
  }
}
