:root {
  --main-bg-color: rgb(254, 184, 28, 0.4);
  --main-bg-color-effect: rgb(254, 184, 28, 0);
  --secondary-bg-color: rgb(255, 253, 243, 0.4);
  --secondary-bg-color-effect: rgb(255, 253, 243, 0);
}

@-webkit-keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--main-bg-color);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px var(--main-bg-color-effect);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 var(--main-bg-color-effect);
  }
}

@keyframes pulse-primary {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--main-bg-color);
    box-shadow: 0 0 0 0 var(--main-bg-color);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px var(--main-bg-color-effect);
    box-shadow: 0 0 0 10px var(--main-bg-color-effect);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 var(--main-bg-color-effect);
    box-shadow: 0 0 0 0 var(--main-bg-color-effect);
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--secondary-bg-color);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px var(--secondary-bg-color-effect);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 var(--secondary-bg-color-effect);
  }
}

@keyframes pulse-secondary {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--secondary-bg-color);
    box-shadow: 0 0 0 0 var(--secondary-bg-color);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px var(--secondary-bg-color-effect);
    box-shadow: 0 0 0 10px var(--secondary-bg-color-effect);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 var(--secondary-bg-color-effect);
    box-shadow: 0 0 0 0 var(--secondary-bg-color-effect);
  }
}
