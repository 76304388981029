@import '@yaireo/tagify/dist/tagify.css';

.multiple-contact-sender {
  --tag-bg: rgba(254, 184, 28, 0.3);
  --tag-text-color: rgba(0, 0, 0, 0.5);
  --tag-remove-btn-color: rgba(0, 0, 0, 0.5);
  --tag-invalid-bg: rgba(243, 84, 16, 0.5);
  --tag-remove-bg: var(--tag-bg);
  --tag-remove-btn-bg--hover: var(--tag-bg);
  --tag-pad: 0.2em 0.5em;
  --tag-hover: var(--tag-bg);
  --tags-border-color: none;
  --tags-hover-border-color: none;
  --tags-focus-border-color: none;
  --placeholder-color: rgba(40, 40, 41, 0.4);
  .tagify__input {
    white-space: nowrap;
  }
}
.tagify--empty .tagify__input::before {
  display: contents;
}
