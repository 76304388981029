@import './App.scss';
@import './vendors.scss';

/*
* brandon-grotesque original
*/
@import url('https://use.typekit.net/kma5ryo.css');

@font-face {
  font-family: 'Avenir-Black';
  src: url('~assets/fonts/avenir/Avenir-Black.eot');
  src: url('~assets/fonts/avenir/Avenir-Black.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-Black.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-Black.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('~assets/fonts/avenir/Avenir-Book.eot');
  src: url('~assets/fonts/avenir/Avenir-Book.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-Book.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-Book.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url('~assets/fonts/avenir/Avenir-Heavy.eot');
  src: url('~assets/fonts/avenir/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-Heavy.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-Heavy.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-HeavyOblique';
  src: url('~assets/fonts/avenir/Avenir-HeavyOblique.eot');
  src: url('~assets/fonts/avenir/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-HeavyOblique.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-HeavyOblique.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-HeavyOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('~assets/fonts/avenir/Avenir-Light.eot');
  src: url('~assets/fonts/avenir/Avenir-Light.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-Light.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-Light.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('~assets/fonts/avenir/Avenir-Medium.eot');
  src: url('~assets/fonts/avenir/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/avenir/Avenir-Medium.woff2') format('woff2'),
    url('~assets/fonts/avenir/Avenir-Medium.woff') format('woff'),
    url('~assets/fonts/avenir/Avenir-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
